import { sendSmartAccountTransaction } from '@/helpers/Vault/sendSmartAccountTransaction';
import { TUseHandleTransactionsParams } from './types';
import { checkInitialization } from './helpers/checkInitializations';
import { handleWithdrawalTxConfigs } from './helpers/handleWithdrawalTxConfigs';
import { handleSwapTxConfigs } from './helpers/handleSwapTxConfigs';
import { handleLockTxConfigs } from './helpers/handleLockTxConfigs';
import { handleUnLockTxConfigs } from './helpers/handleUnLockTxConfigs';
import { handleDailyTxConfigs } from './helpers/handleDailyTxConfigs';
import { TLockedAsset } from '@/store/LockedAssetsStore';
import { handleBaseTxConfigs } from './helpers/handleBaseTxConfigs';
import { Chain } from 'viem';
import { TCall } from '@/helpers/Vault/types';
import { handleMintTapAppTxConfigs } from './helpers/handleMintTxConfigs';
import { handleDailyTapAppTxConfigs } from './helpers/handleDailyTapAppTxConfigs';

export const handleTransaction = async (params: TUseHandleTransactionsParams) => {
    const {
        setTransactionHistory,
        activeChain,
        kernelAccount,
        transactionType,
        walletClient,
        feeTokenAddress,
        preferredFeeType,
    } = params;

    checkInitialization(params);

    const getTransactionConfigs = async (): Promise<{
        transactions: TCall[];
        transactionAccount: any;
        balances?: TLockedAsset[];
        // CAN be more detailed than `transactionType`
        txSubType: string;
    }> => {
        switch (transactionType) {
            case 'withdrawal':
                return await handleWithdrawalTxConfigs(params);
            case 'swap':
                return await handleSwapTxConfigs(params);
            case 'lock':
                return await handleLockTxConfigs(params);
            case 'unlock':
                return await handleUnLockTxConfigs(params);
            case 'daily_claim':
                return await handleDailyTxConfigs(params);
            case 'daily_claim_tap_app':
                return await handleDailyTapAppTxConfigs(params);
            case 'mint_tap_app':
                return await handleMintTapAppTxConfigs(params);
            case 'base':
                // FIXME: the "base" transactions have nowhere to come from
                // The only place they can appear is `provider/walletConnect/helpers/handleSessionRequest.ts`?
                // However, the `txType` specified there is "swap" and not "base"
                return await handleBaseTxConfigs(params);
            default:
                throw new Error('Unsupported transaction type');
        }
    };

    const updateTransactionHistory = (txHash: string, txSubType: string, amount: number) => {
        setTimeout(
            () =>
                setTransactionHistory({
                    txHash,
                    type: txSubType,
                    address: kernelAccount?.address!,
                    chainId: activeChain?.id ?? 0,
                    amount: amount,
                }),
            0,
        );
    };

    const { transactionAccount, transactions, txSubType, balances } = await getTransactionConfigs();

    if (transactions.length < 1) {
        throw new Error('No calls extracted from transaction configs');
    }

    const txHash = await sendSmartAccountTransaction(
        transactionAccount,
        transactions,
        transactionType,
        preferredFeeType,
        feeTokenAddress,
        activeChain as Chain,
        walletClient,
    );

    if (balances && transactionType === 'unlock') {
        balances.forEach(balance => {
            if (!balance?.totalStakedAmount || Number(balance.totalStakedAmount) === 0) return;

            updateTransactionHistory(txHash, txSubType, Number(balance.totalStakedAmount));
        });
    } else {
        updateTransactionHistory(txHash, txSubType, transactions ? Number(transactions[0].value) : 0);
    }

    return txHash as `0x${string}`;
};
